class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }
  ready() {
    return this.readyPromise;
  }
  previewFile(divId, viewerConfig, url) {
    const config = {
      // clientId: "23f723cb3d2c40aebbdb98cc29c25b2e", // dev
      clientId: "967ae92e1e3f4c2bb5b1f2f199f0707c", // prod
      measurementId: "G-HCQ7XGD72Q",
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const pathname = new URL(url)?.pathname;
    const split = pathname?.split?.("/") || [];
    const fileName =
      split?.[split.length - 1]?.replace?.(".pdf", "") || "Document";
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: url,
          },
        },
        metaData: {
          fileName,
        },
      },
      viewerConfig
    ).catch(() => {
      this.onError();
    });
    return previewFilePromise;
  }
  registerSaveApiHandler() {
    const saveApiHandler = (metaData, content, options) => {
      console.log(metaData, content, options);
      return new Promise((resolve) => {
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {}
    );
  }
  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event) => {
        console.log(event, "===");
        if (
          ["PREVIEW_RENDERING_FAILED", "APP_RENDERING_FAILED"].includes(
            event.type
          )
        ) {
          this.onError();
        }
      },
      {
        enablePDFAnalytics: true,
      }
    );
  }
  onError() {
    const errorWrapper = document.getElementById("errorWrapper");
    errorWrapper.hidden = false;
    const adobe = document.getElementById("adobe-dc-view");
    adobe.hidden = true;
  }
}
export default ViewSDKClient;
