import { useEffect, useRef } from "react";

import "./App.css";
import ViewSDKClient from "./ViewSDKClient";

function App() {
  const timeoutRef = useRef();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      const errorWrapper = document.getElementById("errorWrapper");
      errorWrapper.hidden = false;
    }, 3000);
    const searchParams = new URLSearchParams(window.location.search);
    const pdfLink = searchParams.get("url");
    if (pdfLink) {
      clearTimeout(timeoutRef.current);
      const viewSDKClient = new ViewSDKClient();
      viewSDKClient.ready().then(async () => {
        /* Invoke file preview */
        /* By default the embed mode will be Full Window */
        const adobeViewer = await viewSDKClient.previewFile(
          "adobe-dc-view",
          {
            showDownloadPDF: false,
            showPrintPDF: false,
            showBookmarks: false,
            showAnnotationTools: false,
            defaultViewMode: window.innerWidth > 728 ? "FIT_PAGE" : "FIT_WIDTH",
          },
          pdfLink
        );
        adobeViewer?.getAPIs().then((apis) => {
          apis.enableTextSelection(false).catch((error) => console.log(error));
        });
        viewSDKClient.registerEventsHandler();
      });
    }
  }, []);

  return (
    <>
      <div id="adobe-dc-view" style={{ height: "100vh" }} />

      <div id="errorWrapper" hidden={true}>
        <div id="errorWrapperContent">
          <span>
            In order to keep our reports secure. Please access through{" "}
            <a href="https://grugslair.xyz/reports" style={{ color: "white" }}>
              https://grugslair.xyz/reports
            </a>.
            <br/>
            If problem persist, use Chrome or Safari.
          </span>
        </div>
      </div>
    </>
  );
}

export default App;
